import React from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { Typography, Button, TextField, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const NewsLetterView = ({ type, children }, props) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "img/newsletter/marmot.png" }) {
        childImageSharp {
          marmot: gatsbyImageData(width: 100)
        }
      }
    }
  `);

  const [email, setEmail] = React.useState("");
  let [submitResult, setSubmitResult] = React.useState({});

  async function handleSubmit(event) {
    event.preventDefault();
    const result = await addToMailchimp(email);
    setSubmitResult(result);
  }

  let formComponent = (
    <NewsletterContainer
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <NewsLetterDescription
        dangerouslySetInnerHTML={{
          __html:
            submitResult.msg ??
            "Receive a monthly newsletter about the latest articles and tips.",
        }}
      ></NewsLetterDescription>
      <NewsLetterTextFieldButtonContainer>
        <TextField
          id="email"
          label="Your email address"
          variant="outlined"
          value={email}
          size="small"
          color="secondary"
          focused
          onInput={(e) => setEmail(e.target.value)}
        />
        <Button type="submit">
          <Typography variant="button">Subscribe</Typography>
        </Button>
      </NewsLetterTextFieldButtonContainer>
      <div>
        <GatsbyImage
          image={data.file.childImageSharp.marmot}
          alt="Image of a reading marmot"
        />
      </div>
    </NewsletterContainer>
  );

  return formComponent;
};

export { NewsLetterView };

/// Signup URL http://eepurl.com/gerkfP

const NewsletterContainer = styled(Box)(({ theme }) => ({
  marginTop: "20px",
  marginBottom: "20px",
}));

const NewsLetterTextFieldButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "16px",
  marginBottom: "20px",
}));

const NewsLetterDescription = styled(Box)(({ theme }) => ({
  marginTop: "20px",
  marginBottom: "25px",
  fontSize: "20px",
}));
