import React from "react";
import { Typography, Container } from "@mui/material";
import { NewsLetterView } from "../components/newsLetterView";
import Seo from "../components/seo";
import Layout from "../styles/layout";

export default function Newsletter({ props, location }) {
  return (
    <Layout location={location}>
      <Seo
        title="Newsletter from tanaschita.com"
        description="Receive a monthly newsletter about the latest Swift and iOS articles and tips from tanaschita.com"
      />
      <Container>
        <Typography variant="h1">Newsletter</Typography>
        <NewsLetterView></NewsLetterView>
      </Container>
    </Layout>
  );
}
